import React, { FC, useEffect, useState } from "react";
import Button from "../../../UI/molecules/Button/Button";
import Title from "../../../UI/organisms/Title/Title";
import { useEgoi } from "./hooks/useEgoi";
import { useBuyButton } from "./hooks/useBuyButton";

import "./Header.scss";
import { HeaderType } from "./Types";

const Header: FC<HeaderType> = ({ data, nameBlock }) => {
  const publicUrl = process.env.PUBLIC_URL;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const { egoi, EgoiActions } = useEgoi();
  const { BuyButtonHandle, MultiBuyButtonsHandle } = useBuyButton(
    nameBlock,
    publicUrl,
    EgoiActions
  );

  useEffect(() => {
    // Add event listener on mount
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1025);
    });
  }, []);

  const { offer, multiCtas, position, customClass, image, infos, title } = data;

  offer?.link &&
    !offer?.link.includes("hotmart.com") &&
    EgoiActions.StartEgoi();

  return (
    <>
      <section
        id={nameBlock}
        className={`component ${position}`}
        style={
          nameBlock === "offer"
            ? {
                backgroundImage: `url(${publicUrl}/img/course/background-offer.png)`,
              }
            : { backgroundImage: `` }
        }
      >
        <div className={`component__content ${customClass || ""}`}>
          {image && !isMobile && (
            <div className="component__banner">
              <img
                className="component__banner--img"
                src={`${publicUrl}/${image}`}
                alt=""
              />
              {infos &&
                infos.map((info, index) => (
                  <div key={info.text} className={`--item-${index}`}>
                    <img src={`${publicUrl}/${info.ico}`} alt={info.text} />
                    <p dangerouslySetInnerHTML={{ __html: `${info.text}` }}></p>
                  </div>
                ))}
            </div>
          )}

          <div className="component__info">
            <Title info={title} tag="h1" />
            <div
              className={`component__info--price ${
                offer?.installments ? "has-installments" : ""
              } ${offer?.available === false ? "unavailable" : ""}`}
            >
              {offer?.price
                ? offer.price !== offer.bestPrice && (
                    <div className="old-price">
                      De <span>{offer.price}</span>
                    </div>
                  )
                : ""}
              {offer?.installments && (
                <div className="installments">
                  Por
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${offer.installments}`,
                    }}
                  ></span>
                </div>
              )}
              {offer?.bestPrice && (
                <div className="best-price">
                  {offer.installments
                    ? "ou"
                    : offer.price !== offer.bestPrice
                    ? "Por"
                    : ""}
                  <span>{offer.bestPrice}</span>
                  {offer.installments && "à vista"}
                </div>
              )}
              {offer?.note?.text && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${offer.note.text}`,
                  }}
                  className={`note-price ${offer?.note?.size} ${offer?.note?.style}`}
                ></p>
              )}
            </div>
            {offer && (
              <div
                className={`component__info--buttons ${
                  multiCtas?.buttons && multiCtas.buttons.length > 1
                    ? " multi-ctas"
                    : ""
                }`}
              >
                {offer?.link && BuyButtonHandle(offer)}
                {multiCtas && (
                  <>
                    {multiCtas.title && (
                      <Title info={multiCtas.title} tag="h3" />
                    )}{" "}
                    {multiCtas.buttons.map((bt) => MultiBuyButtonsHandle(bt))}
                  </>
                )}
                <Button
                  url="#questions"
                  text="Tenho dúvidas"
                  ico={
                    nameBlock === "offer"
                      ? `${publicUrl}/img/course/icons/questions-blue.svg`
                      : `${publicUrl}/img/course/icons/questions.svg`
                  }
                />
              </div>
            )}
            {data.description && (
              <div
                className="component__info--desc"
                dangerouslySetInnerHTML={{ __html: `${data.description}` }}
              ></div>
            )}
          </div>
        </div>
      </section>

      {offer?.link && !offer?.link.includes("hotmart.com") && (
        <div id="popUp-egoi" className={`${offer?.link} ${egoi}`}>
          <button onClick={EgoiActions.HandlePopUp}>X</button>
        </div>
      )}
    </>
  );
};

export default Header;
